<template>
  <div>
    <div class="flexRow justify-content-end" v-if="matchJurisdiction('AdminRole/add')">
      <el-button type="primary" @click="openBox(1)">创建角色</el-button>
    </div>
    <div class="marginTop">
      <el-table :data="tableData" style="width:100%" border>
        <el-table-column prop="num" label="序号" width="80" align="center">
        </el-table-column>
        <el-table-column prop="name" label="角色名称" align="center">
        </el-table-column>
        <el-table-column prop="remark" label="角色描述" align="center">
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <span v-if="matchJurisdiction('AdminAccess/access')" class="editBtn mRight" @click="toDetail(scope.row.id,2)">分配权限</span>
            <span class="editBtn mRight" @click="toDetail(scope.row.id,1)">查看权限</span>
            <span v-if="matchJurisdiction('AdminRole/edit')" class="editBtn mRight" @click="openBox(2,scope.$index)">编辑</span>
            <span v-if="matchJurisdiction('AdminRole/del')" class="editBtn" @click="delItem(scope.row.id)">删除</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="page flexRow justify-content-center">
        <el-pagination
            background
            layout="total,prev, pager, next,jumper"
            :current-page="curPage"
            :total="totalItem"
            :page-size="20"
            @current-change="toPages">
        </el-pagination>
      </div>
    </div>
    <!--创建、编辑账号-->
    <el-dialog :close-on-click-modal="false" :visible.sync="showBox" width="30%">
      <el-form label-width="90px">
        <el-form-item label="角色名称">
          <el-input v-model="formData.name" placeholder="请输入角色名称"></el-input>
        </el-form-item>
        <el-form-item label="角色描述">
          <el-input type="textarea" v-model="formData.desc" placeholder="请输入角色描述"></el-input>
        </el-form-item>
      </el-form>
      <div class="marginTop flexRow justify-content-end">
        <el-button type="primary" @click="subMsg">提交</el-button>
      </div>
    </el-dialog>
    <!--分配权限-->
    <el-dialog :visible.sync="showJurisdiction" :close-on-click-modal="false">
      <el-table style="width:100%" :data="nodeArr" height="70vh">
        <el-table-column label="所属模块" prop="groupName" width="180"></el-table-column>
        <el-table-column label="页面" width="220">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.pageChecked">{{scope.row.title}}</el-checkbox>
          </template>
        </el-table-column>
        <el-table-column label="功能权限">
          <template slot-scope="scope">
            <el-checkbox-group v-model="scope.row.checkList" @change="chooseItem($event,scope.$index)">
              <el-checkbox v-for="item in scope.row._child" :label="item.title"></el-checkbox>
            </el-checkbox-group>
          </template>
        </el-table-column>
      </el-table>
      <div class="marginTop flexRow justify-content-end">
        <el-button type="primary" @click="subInfo">提交</el-button>
      </div>
    </el-dialog>
    <!--查看权限-->
    <el-dialog :visible.sync="showDetail" width="40%">
      <el-table style="width:100%" :data="nodeArr">
        <el-table-column prop="title" label="页面" width="180">
        </el-table-column>
        <el-table-column label="功能权限">
          <template slot-scope="scope">
            <div class="flexRow flexWrap">
              <span class="mRight" v-for="(item,index) in scope.row._child">{{item.title}}</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "RolePermissions",
  data(){
    return{
      tableData:[],
      totalItem:0,
      curPage:1,
      showBox:false,
      menuList:[],
      formData:{
        name:"",
        desc:"",
      },
      showJurisdiction:false,  //分配权限窗口
      nodeArr:[],    //页面列表
      showDetail:false,   //查看权限
    }
  },
  created(){
    sessionStorage.setItem('dActive','role');
    this.getList(1);
  },
  mounted(){

  },
  methods:{
    //获取角色列表
    async getList(p){
      let res = await this.mainFun('AdminRole/getList',{p});
      this.totalItem = res.data.total;
      let data = res.data.data;
      for(let i in data){
        data[i].num = (res.data.current_page - 1) * res.data.per_page + parseInt(i) + 1
      }
      this.tableData = data;
    },
    //分页
    toPages(p){
      this.getList(p);
      this.p = p;
    },
    //打开弹窗
    openBox(type,index){
      if(type == 1){
        //新建
        this.eid = "";
        this.formData = {
          name:"",
          desc:""
        }
        this.showBox = true;
      }else if(type == 2){
        //编辑
        this.eid = this.tableData[index].id;
        this.formData = {
          name:this.tableData[index].name,
          desc:this.tableData[index].remark
        }
        this.showBox = true;
      }
    },
    //获取菜单页面
    async getNode(selectData){
      let res = await this.mainFun('AdminNode/node',{list_rows:50});
      //console.log(res)
      let data1 = res.data[0];
      let data2 = res.data[1];
      let idArr = [],valueArr = [];
      for(let k in data1){
        idArr.push(k);
        valueArr.push(data1[k])
      }
      for(let i in data2){
        let index = idArr.indexOf(data2[i].id+'');
        if(index > -1){
          data2[i].subList = valueArr[index]
        }
      }
      let list = [];
      for(let item of data2){
        for(let subItem of item.subList){
          let obj = {
            groupName:item.name,
            groupId:item.id,
            checkList:[],
            pageChecked:false
          };
          Object.assign(obj,subItem);
          list.push(obj)
        }
      }
      if(selectData){
        let ids = [],subCheckList = [];
        for(let item of selectData){
          ids.push(item.id);
          let subTitle = [];
          if(item._child.length){
            for(let subItem of item._child){
              subTitle.push(subItem.title)
            }
          }
          subCheckList.push(subTitle)
        }
        for(let i in list){
          let index = ids.indexOf(list[i].id);
          if(index > -1){
            list[i].pageChecked = true;
            list[i].checkList = subCheckList[index]
          }
        }
      }
      this.nodeArr = list;
      this.showJurisdiction = true;
    },
    //查看权限
    async toDetail(id,type){
      let res = await this.mainFun('AdminRole/role_node',{role_id:id});
      //console.log(res)
      if(res.data[1].length){
        let data1 = res.data[0];
        let data2 = res.data[1];
        let idArr = [],valueArr = [];
        for(let k in data1){
          idArr.push(k);
          valueArr.push(data1[k])
        }
        for(let i in data2){
          let index = idArr.indexOf(data2[i].id+'');
          if(index > -1){
            data2[i].subList = valueArr[index]
          }
        }
        let list = [];
        for(let item of data2){
          for(let subItem of item.subList){
            let obj = {
              groupName:item.name,
              groupId:item.id,
              checkList:[],
              pageChecked:false
            };
            Object.assign(obj,subItem);
            list.push(obj)
          }
        }
        if(type == 1){
          //查看权限
          this.nodeArr = list;
          this.showDetail = true;
        }else{
          //分配权限
          await this.getNode(list);
          this.role_id = id;
        }
      }else{
        if(type == 1){
          //查看权限
          this.$message.warning('请先添加权限');
        }else{
          //分配权限
          await this.getNode();
          this.role_id = id;
        }
      }
    },
    //创建角色
    async subMsg(){
      if(this.formData.name == ""){
        this.$message.warning("请填写角色名");
        return;
      }
      let params = {
        name:this.formData.name,
        remark:this.formData.desc
      };
      let url = "AdminRole/";
      if(this.eid){
        params.id = this.eid;
        url += 'edit';
      }else {
        url += 'add'
      }
      let res = await this.mainFun(url,params);
      if(res.data.code == 1){
        this.$message.success(res.data.message);
        if(params.id){
          await this.getList(this.p);
        }else{
          await this.getList(1);
        }
        this.showBox = false;
      }else{
        this.$message.error(res.data.message);
      }
    },
    //删除
    delItem(id){
      this.$confirm('是否确认删除当前信息','提示',{
        confirmButtonText:"确定",
        cancelButtonText:"取消",
        type:'warning'
      }).then(async ()=>{
        let res = await this.mainFun('AdminRole/del',{id});
        if(res.data.code == 1){
          this.$message.success(res.data.message);
          await this.getList(this.p);
          this.showBox = false;
        }else{
          this.$message.error(res.data.message);
        }
      })
    },
    //选择权限
    chooseItem(val,index){
      if(val.length){
        this.nodeArr[index].pageChecked = true;
      }
    },
    //分配权限
    async subInfo(){
      let pageArr = this.nodeArr.filter(ele=>{
        return ele.pageChecked == true;
      })
      if(pageArr.length == 0){
        this.$message.warning("请选择页面及相应的权限");
        return;
      }
      let data = [];
      for(let item of pageArr){
        data.push({node_id:item.id,level:item.level,pid:item.pid})
        if(item.checkList.length){
          for(let subItem of item._child){
            let index = item.checkList.indexOf(subItem.title);
            if(index > -1){
              data.push({node_id:subItem.id,level:subItem.level,pid:subItem.pid})
            }
          }
        }
      }
      let params = {
        role_id:this.role_id,
        data
      };
      let res = await this.mainFun('AdminAccess/access',params);
      if(res.data.code == 1){
        this.$message.success(res.data.message);
        this.getList(this.p);
        this.showJurisdiction = false;
      }else{
        this.$message.error(res.data.message);
      }
    },
  }
}
</script>

<style scoped>

</style>
